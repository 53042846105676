<template>
	<div class="page">
		<!-- 拍摄示范 -->
		<template v-if="isShowDialog">
			<div class="tips-content">
				<div class="content">
					<!-- <van-icon name="close" color="#000000" class="close-icon" @click="closeTips" /> -->
					<div class="title" :style="{color: style.btnColor}">Photo taking guidelines</div>
					<img :src="style.shootTipsImg" alt="" class="shoot-tips-img">
					<div class="content-text">
						<div>Use the horizontal orientation of your mobile device.</div>
						<div>Make sure the photo is clearly visible and positioned</div>
						<div style="margin-bottom: 8px;">within the defined border.</div>
						<b>Business Cards ONLY.</b><br />
						<b>DO NOT upload photos of other forms of identification.</b>
					</div>
				</div>
				<van-button type="primary" block :color="style.btnColor" class="font16 radius btn" @click="shootStart">Take Photo</van-button>
			</div>
		</template>
		
		<div v-else class="container">
			<div class="notes">
				<div v-if="errorTips" class="error-tips">{{errorTips}}</div>
				<div v-else>
					<h1>Business card photo</h1>
					<h4>Paper or digital business cards only</h4>
				</div>
			</div>
			<div class="card-container">
				<div class="box">
					<!-- 名片正面 -->
					<div class="card">
						<!-- 未选择图片或图片校验未通过时 -->
						<div v-if="!front.isSuccess" class="tips-layer border" :class="{mask: front.isUpload && !front.isSuccess}" :style="{color: style.btnColor}"
						 @click="toShoot('front')">
							<!-- 未选择图片，且未进行图片校验: 点击上传名片正面 -->
							<div v-if="!data.aCardPath && !front.isUpload">
								<van-icon name="add-o" :color="style.btnColor" class="add-icon" />
								<span class="tips-text default">Business Card (Front)</span>
							</div>
							<!-- 已进行图片校验，但校验未通过时: 点击重新拍摄 -->
							<div v-if="front.isUpload && !front.isSuccess">
								<van-icon name="add-o" class="add-icon text-white" />
								<span class="tips-text text-white">Re-take photo</span>
							</div>
						</div>
						<img v-if="data.aCardPath" :src="data.aCardPath" class="fill img border" :style="{color: style.btnColor}" @click="toShoot('front')">
					</div>
					<!-- 校验通过提示icon -->
					<van-icon v-if="front.isSuccess" name="checked" :color="style.btnColor" class="success-icon" />
				</div>

				<!-- 名片反面 -->
				<div class="box">
					<div class="card">
						<div v-if="!back.isSuccess" class="tips-layer border" :class="{mask: back.isUpload && !back.isSuccess}" :style="{color: style.btnColor}"
						 @click="toShoot('back')">
							<!-- 未选择图片，且未进行图片校验: 点击上传名片反面 -->
							<div v-if="!data.bCardPath && !back.isUpload">
								<van-icon name="add-o" :color="style.btnColor" class="add-icon" />
								<span class="tips-text default">Business Card (Back)</span>
							</div>
							<!-- 已进行图片校验，但校验未通过时:点击重新拍摄 -->
							<div v-if="back.isUpload && !back.isSuccess">
								<van-icon name="add-o" class="add-icon text-white" />
								<span class="tips-text text-white">Re-take photo</span>
							</div>
						</div>
						<img v-if="data.bCardPath" :src="data.bCardPath" class="fill img border" :style="{color: style.btnColor}" @click="toShoot('back')">
					</div>
					<!-- 校验通过提示icon -->
					<van-icon v-if="back.isSuccess" name="checked" :color="style.btnColor" class="success-icon" />
				</div>

			</div>
			<van-button v-if="front.isSuccess && back.isSuccess" type="primary" block :color="style.btnColor" class="font16 radius btn" @click="nextStep">Next</van-button>

			<!-- 手动输入名片信息 -->
			<!-- <van-button v-if="!front.isSuccess" type="primary" block :color="style.btnColor" class="font16 radius btn" @click="fillInfo('en')">I don’t have a business card</van-button> -->
			<div class="text-center">
				<span v-if="!front.isSuccess && style.isAllowSwitch" class="font16 letter-spacing fill-btn" @click="fillInfo('en')">{{linkText}}</span>
			</div>
		</div>

		<input v-show="false" type="file" id="fileElem" accept="image/*" ref="imgFile" @change="chooseImg">

		<!-- 页脚 -->
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level" language="en"></bottom>
		</div>

	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import shootCardMixin from '@/view/process/mixin/shootCard.js';

	export default {
		mixins: [commonMixin, shootCardMixin],
		data() {
			return {
				
			} 
		},
		created() {
			this.setLanguge('en'); // 设置语言环境
		},
		methods: {

		},
	}
</script>

<style lang="less" scoped>
	@color: #9F9D9D;
	// @color: #6D6D6D;

	.border {
		border: 3px solid;
	}

	.mask {
		background-color: rgba(0, 0, 0, .5);
	}

	.notes {
		margin: 6vh auto 3vh;
		height: 80px;
		text-align: center;
		color: @color;
		
		.error-tips {
			width: 80%;
			margin: 0 auto;
			font-size: 18px;
			line-height: 28px;
			// padding-top: 10px;
		}

		h1,
		h4 {
			font-family: 'DIN-Regular';
		}

		h1 {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 3vh;
		}

		h4 {
			font-size: 12px;
		}
	}

	.card-container {
		width: 320px;
		margin: 0 auto 5vh;

		.box {
			position: relative;
			margin-bottom: 3vh;

			.card {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 240px;
				height: 140px;
				background-color: #fff;
				border-radius: 10px;
				margin: 0 auto;
				text-align: center;

				.img {
					border-radius: 10px;
				}

				.add-icon {
					font-size: 30px;
					margin-bottom: 2vh;
				}

				.tips-text {
					display: block;
					text-align: center;
					font-size: 16px;
				}

				.default {
					color: #070707;
				}

				.tips-layer {
					position: absolute;
					left: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					height: 100%;
					border-radius: 10px;
					box-sizing: border-box;
					z-index: 1;
				}

				.van-button {
					position: absolute;
					width: 100%;
					height: 100%;
					background: transparent;
					border: none;
					z-index: 10;
				}
			}

			.success-icon {
				position: absolute;
				top: 50px;
				right: 0;
				font-size: 24px;
			}
		}
	}

	.btn {
		width: 240px;
		margin: 0 auto 3vh;
	}

	.fill-btn {
		// display: block;
		color: @color;
		text-align: center;
		padding-bottom: 4px;
		border-bottom: 2px solid;
		margin: 0 auto 3vh;
	}

	.tips-content {
		width: 330px;
		margin: 7vh auto 0;

		.content {
			position: relative;
			background-color: #fff;
			padding: 20px 0;
			margin-bottom: 5vh;
			border-radius: 10px;

			// .close-icon {
			// 	position: absolute;
			// 	top: 6px;
			// 	right: 6px;
			// 	font-size: 28px;
			// 	z-index: 10000;
			// }

			.title {
				font-weight: bold;
				font-size: 22px;
				text-align: center;
				margin-bottom: 4vh;
			}

			.shoot-tips-img {
				display: block;
				margin: 0 auto 4vh;
				width: 200px;
			}

			.content-text {
				line-height: 20px;
				font-size: 12px;
				color: #333;
				text-align: center;
			}
		}
	}

</style>

