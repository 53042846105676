import {
		Icon,
		Overlay,
	} from 'vant';
	import {
		phoneBrand,
		getSystemAndModel
	} from '@/utils/utils.js';
	import {
		uploadCardFront,
		uploadCardBacd,
		commitCard
	} from '@/api/index.js';
	import localStore from 'storejs';

	export default {
		components: {
			[Icon.name]: Icon,
			[Overlay.name]: Overlay,
		},
		data() {
			return {
				errorTips: '',
				isShowDialog: false, // 是否显示拍摄提示弹窗
				hasDisplayed: false, // 是否已经显示过拍摄提示弹窗
				frontOrBack: 'front', // 当前图片是正面还是反面
				frontSuccess: false,
				backSuccess: false,
				front: {
					isSuccess: false, // 是否显示合格icon
					isUpload: false // 是否已进行上传校验
				},
				back: {
					isSuccess: false, // 是否显示合格icon
					isUpload: false // 是否已进行上传校验
				},
				cardInfo: null,
				linkText: '',
			}
		},
		mounted() {
			// 获取设备信息
			const device = getSystemAndModel();
			if (device.os === "iOS") { // ios系统
				// 不直接调用摄像头，自行选择获取模式
				this.$refs.imgFile.removeAttribute("capture");
			} else { // 安卓或其他系统
				if (phoneBrand() === 'xiaomi') { // 手机品牌为小米时, 从相册选择图片
					this.$refs.imgFile.setAttribute("accept", "image/*");
				} else {
					// 直接调用摄像头
					this.$refs.imgFile.setAttribute("capture", "camera");
				}
			}
			
			// 获取会话缓存的名片
			if (sessionStorage.getItem('cardInfo')) {
				this.refresh()
			}
		},
		created() {
			this.fillCardImg();
		},
		methods: {
			// 重新上传
			refresh() {
				let cardInfo = sessionStorage.getItem('cardInfo').split('<-><-><-><->');
				sessionStorage.removeItem('cardInfo'); // 清除会话缓存的名片
				
				this.frontOrBack = cardInfo[0]; // 名片正反面
				if(this.frontOrBack === 'front') {
					this.data.aCardPath = cardInfo[1]
				} else {
					this.data.bCardPath = cardInfo[1]
				}
				this.uploadFile(cardInfo[1])
			},
			// 回填名片
			fillCardImg() {
				if(this.data.aCardPath) { // 有名片正面
					this.front = {
						isSuccess: true, // 是否显示合格icon
						isUpload: true ,// 是否已进行上传校验
					}
					this.linkText = this.lang == 'en' ? 'Fill in information manually' : '手动输入名片信息';
				} else { // 无名片正面
					this.linkText = this.lang == 'en' ? 'I don’t have a business card' : '没有名片需手动输入';
				}
				
				if(this.data.bCardPath) {
					this.back = {
						isSuccess: true, // 是否显示合格icon
						isUpload: true // 是否已进行上传校验
					}
				}
			},
			// 进入个人信息页
			fillInfo(lang = '') {
				let path = lang === 'en' ? '/information_en' : '/information';
				this.$router.push(path)
			},
			// 关闭拍摄提示弹窗
			closeTips() {
				this.hasDisplayed = true;
				this.isShowDialog = false;
			},
			// 弹出拍摄提示
			toShoot(position) {
				this.frontOrBack = position;

				// 已经显示过弹窗提示，则直接去调取摄像头；否则显示弹窗提示
				if (this.hasDisplayed) {
					this.shootStart();
				} else {
					this.isShowDialog = true;
					this.hasDisplayed = true;
				}
			},
			// 触发拍摄
			shootStart() {
				this.isShowDialog = false;

				setTimeout(() => {
					//触发点击事件
					// let event = document.createEvent("MouseEvents");
					// event.initEvent("click", true, true);
					// document.getElementById("fileElem").dispatchEvent(event);

					document.querySelector('#fileElem').click();
				}, 100)
			},
			//选择图片/拍摄照片
			chooseImg(e) {
				this[this.frontOrBack].isUpload = false; // 未上传校验
				if(this.frontOrBack === 'front') {
					this.data.aCardPath = ''
				} else {
					this.data.bCardPath = ''
				}
				
				let files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				this.imgPreview(files[0]);
			},
			// 预览图片
			imgPreview(file) {
				let that = this;

				if (file) {
					let reader = new FileReader();
					let image = new Image();
					
					// (File 或 Blob)读取完成时
					reader.onload = function(event) {
						
						// 图片加载完毕时
						image.onload = function() {
							
							// 设置默认宽高: 宽高比例 === 图片宽高比例
							let width = 750,
									height = width * (image.height / image.width);
							
							// 创建画布
							let canvas = document.createElement("canvas");
							// 设置画布宽高
							canvas.width = width;
							canvas.height = height;

							let ctx = canvas.getContext('2d');
							ctx.drawImage(image, 0, 0, width, height); // 绘图
							
							// 图片转base64, 参数: 图片类型(file.type), 图片质量
							let dataURI = canvas.toDataURL("image/jpeg", 0.9);

							// 预览本地选中的图片
							if(that.frontOrBack === 'front') {
								that.data.aCardPath = dataURI
							} else {
								that.data.bCardPath = dataURI
							}
							
							// 偶现拍照时出现页面刷新, 因此会话缓存名片, 在页面被刷新时获取会话缓存的名片
							sessionStorage.setItem('cardInfo', that.frontOrBack + '<-><-><-><->' + dataURI);
							
							that.uploadFile(dataURI); // 上传图片
						}
						
						// 开始加载图片
						image.src = event.target.result; // 将获取到的base64字符串赋值到图片路径
					}
				
					// 进行(File 或 Blob)读取
					reader.readAsDataURL(file);
				}
			},
			// 上传图片
			uploadFile(dataURI) {

				this.$toast.loading({
					forbidClick: true,
					duration: 0,
				});
				
				let base64 = dataURI.split(',')[1];
				let uploadCardAPI = this.frontOrBack === 'front' ? uploadCardFront : uploadCardBacd;
				uploadCardAPI(base64).then(res => {
					this.$toast.clear();
					this.errorTips = '';
					
					this[this.frontOrBack].isUpload = true;
					this[this.frontOrBack].isSuccess = true;
					
					// 预览上传接口返回的网络图片
					if(this.frontOrBack === 'front') {
						this.data.aCardPath = res.data || '';
					} else {
						this.data.bCardPath = res.data || ''
					}
					
					this.setPageData(this.path, this.data); // 更新该页的回填数据
					
					document.getElementById("fileElem").value = '';
				}).catch(err => {
					this.$toast.clear();
					this.errorTips = err.message;
					this[this.frontOrBack].isUpload = true;
					this[this.frontOrBack].isSuccess = false;
					document.getElementById("fileElem").value = '';
					if (this.frontOrBack === 'front') {
						this.linkText = this.lang == 'en' ? 'Fill in information manually' : '手动输入名片信息';
					}
				});
			},
			// 下一步
			nextStep() {
				this.$toast.loading({
					loadingType: 'spinner',
					forbidClick: true,
					duration: 0,
				});
				
				commitCard(this.unionid).then( res => {
					sessionStorage.removeItem('cardInfo'); // 清除会话缓存的名片
					this.pageSwitch(res.data);
				}).catch(err => {
					err ? this.$toast(err.message) : this.$toast.clear();
				});
			}
		}
	}
